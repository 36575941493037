import React from 'react';
import './popup.css'; // Assuming you will style the popup using CSS
import popupimg from '../../Assets/images/popupimg.png'
import playstoreapp from '../../Assets/images/playstorebtn.png'
import appstoreapp from '../../Assets/images/appstorebtn.png'
import downloadnow from '../../Assets/images/downloadnow.gif'
const Popup = ({ show, onClose, text, link }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <span className="close-button" onClick={onClose}>×</span>
        <img src={popupimg} className='popupimg' alt="" />
        <p className='descpopup'>We're excited to announce that your new decentralized future OxWallet is live! Download Now and join the decentralized revolution!</p>
        <ul className='downloadnowbtn'>
          <li>
            <img src={downloadnow} className='downloadnow' alt="downloadnow" />
          </li>
        </ul>
        <ul className='appdownloadBtn'>
          <li>
            <a href={'https://apps.apple.com/app/oxwallet/id6502858750'} target='_blank'>
              <img src={appstoreapp} className='appstoreapp' alt="appstoreapp" />
            </a>
          </li>
          <li>
            <a href={'https://play.google.com/store/apps/details?id=com.vedge.oxwallet&pli=1'} target='_blank'>
              <img src={playstoreapp} className='playstoreapp' alt="playstoreapp" />
            </a>
          </li>
        </ul>
        {/* <a href={'https://play.google.com/store/apps/details?id=com.vedge.oxwallet&pli=1'} target='_blank' className="link-button">Download Now</a> */}
      </div>
    </div>
  );
};

export default Popup;